<template>
  <div>
    <our-story :about="aboutDate.about"/>
    <achievements :achievements="aboutDate.achievements"/>
    <our-team v-if="aboutDate.teams.length > 0" :team="aboutDate.teams"/>
  </div>
</template>
<script>
import OurStory from '@/modules/about/components/our-story'
import { core } from '@/config/pluginInit'
import Achievements from '@/modules/about/components/achievements'
import OurTeam from '@/modules/about/components/our-team'
import AboutServices from '@/modules/about/services/about.services'
export default {
  data () {
    return {
      aboutDate: {
        about: '',
        achievements: '',
        teams: ''
      }
    }
  },
  components: { OurTeam, Achievements, OurStory },
  methods: {
    getAboutData () {
      AboutServices.getAbout().then(res => {
        this.aboutDate = res.data.data
      })
    }
  },
  created () {
    this.getAboutData()
  },
  mounted () {
    core.index()
  }
}
</script>
