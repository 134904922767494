<template>
  <section class="iq-style1">
    <b-container class="achievements-section position-relative pt-5 pb-3">
      <b-row class="mb-5 d-flex justify-content-around">
        <b-col lg="2" md="6" cols="6" class="mb-5 d-flex justify-content-center">
          <div
            class="frame-about position-relative d-flex justify-content-center align-items-center mb-0"
          >
            <img class="shape" :src="icons.ellipse" />
            <img class="front-image-about exp-image" :src="icons.years" />
            <span class="achievement-stats text-center">
                <p class="achievement-stats-text">+{{ achievements.years_of_experience }}</p>
            </span>
            <span
              class="achievement-title d-flex justify-content-center align-items-center"
            >
              <p class="text-warning text-center">{{ $t('about.exp') }}</p></span
            >
          </div>
        </b-col>
        <b-col lg="2" md="6" cols="6" class="mb-5 d-flex justify-content-center">
          <div
            class="frame-about position-relative d-flex justify-content-center align-items-center mb-0"
          >
            <img class="shape" :src="icons.ellipse" />
            <img class="front-image-about product-image" :src="icons.products" />
            <span class="achievement-stats text-center">
                <p class="achievement-stats-text">+{{ reduce(achievements.our_Products.toString()) }}</p>
            </span>
            <span
              class="achievement-title d-flex justify-content-center align-items-center"
            >
              <p class="text-warning">{{ $t('about.products') }}</p></span
            >
          </div>
        </b-col>
        <b-col lg="2" md="6" cols="6" class="mb-5 d-flex justify-content-center">
          <div
            class="frame-about position-relative d-flex justify-content-center align-items-center mb-0"
          >
            <img class="shape" :src="icons.ellipse" />
            <img class="front-image-about branch-image" :src="icons.branches" />
            <span class="achievement-stats text-center">
                <p class="achievement-stats-text">{{ achievements.our_branches }}</p>
            </span>
            <span class="achievement-title text-center"
              ><p class="text-warning">{{ $t('about.branches') }}</p></span
            >
          </div>
        </b-col>
        <b-col lg="2" md="6" cols="6" class="mb-5 d-flex justify-content-center">
          <div
            class="frame-about position-relative d-flex justify-content-center align-items-center mb-0"
          >
            <img class="shape" :src="icons.ellipse" />
            <img class="front-image-about clients-image" :src="icons.customers" />
            <span class="achievement-stats text-center">
                <p class="achievement-stats-text">+{{ reduce(achievements.happy_clients.toString()) }}</p>
            </span>
            <span
              class="achievement-title d-flex justify-content-center align-items-center"
            >
              <p class="text-warning text-center">{{ $t('about.customers') }}</p></span
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  props: {
    achievements: { type: Object }
  },
  data () {
    return {
      icons: {
        years: require('@/assets/images/curva/achievements/years.png'),
        products: require('@/assets/images/curva/achievements/products.png'),
        branches: require('@/assets/images/curva/achievements/branches.png'),
        customers: require('@/assets/images/curva/achievements/customers.png'),
        ellipse: require('@/assets/images/curva/dark/ellipse.png')
      }
    }
  },
  methods: {
    reduce (data) {
      console.log(data)
      console.log(data.length)
      if (data.length > 6) {
        data = data.slice(0, data.length - 6) + 'M'
      } else if (data.length > 3) {
        data = data.slice(0, data.length - 3) + 'K'
      }
      return data
    }
  }
}
</script>
